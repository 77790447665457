import React, { useState, useEffect } from "react";

const GetWeather = ({ wxStation }) => {
  const url =
    "https://tie.digitraffic.fi/api/v1/data/weather-data/" + wxStation;
  const [stnData, setStnData] = useState([]);

  useEffect(() => {
    if (wxStation !== null) {
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error("Tietojen haussa on ongelma: " + response.status);
          }
          return response.json();
        })
        .then(data => setStnData(data.weatherStations[0].sensorValues))

        .catch(err => {
          console.log("Hakuvirhe:", err);
        });
    }
  }, [url]);

  if (stnData.length === 0) {
    return <div>Ei tietoja.</div>;
  }

  const getDateTime = dt => {
    pvm = [dt.getDate(), dt.getMonth() + 1, ""].join(".");
    aika =
      ("0" + dt.getHours()).slice(-2) + "." + ("0" + dt.getMinutes()).slice(-2);
    return [pvm, aika];
  };

  let aika = null,
    ilma = null,
    tielampo = null,
    keskituuli = null,
    tuulensuunta = null,
    ilmankosteus = null,
    sade = null,
    nakyvyys = null,
    tienpinta = null,
    pvm = null,
    lt = null;

  let ilmatxt = "",
    tielampotxt = "",
    ilmankosteustxt = "",
    tuulitxt = "",
    tienpintatxt = "",
    nakytxt = "",
    pvmtxt = "",
    sadetxt = "";

  for (let i = 0; i < stnData.length; i++) {
    if (stnData[i].name === "ILMA") {
      ilma = stnData[i].sensorValue;
      lt = new Date(stnData[i].measuredTime);
    }

    if (stnData[i].name === "TIE_1") {
      tielampo = stnData[i].sensorValue;
      lt = new Date(stnData[i].measuredTime);
    }

    if (stnData[i].name === "KESKITUULI") {
      keskituuli = stnData[i].sensorValue;
      lt = new Date(stnData[i].measuredTime);
    }

    if (stnData[i].name === "TUULENSUUNTA") {
      tuulensuunta = stnData[i].sensorValue;
      lt = new Date(stnData[i].measuredTime);
    }

    if (stnData[i].name === "ILMAN_KOSTEUS") {
      ilmankosteus = stnData[i].sensorValue;
      lt = new Date(stnData[i].measuredTime);
    }

    if (stnData[i].name === "SADE") {
      sade = stnData[i].sensorValueDescriptionFi;
      lt = new Date(stnData[i].measuredTime);
    }

    if (stnData[i].name === "NAKYVYYS") {
      nakyvyys = stnData[i].sensorValue;
      lt = new Date(stnData[i].measuredTime);
    }

    if (stnData[i].name === "KELI_1") {
      tienpinta = stnData[i].sensorValueDescriptionFi;
      lt = new Date(stnData[i].measuredTime);
    }
  }

  if (ilma) {
    ilmatxt = `Ilma: ${ilma}°C.`;
  }

  if (tielampo) {
    tielampotxt = `Tie: ${tielampo}°C.`;
  }

  if (tielampo && ilma) {
    ilmatxt = `Ilma: ${ilma}°C, tie: ${tielampo}°C.`;
    tielampotxt = "";
  }

  if (keskituuli) {
    tuulitxt = `Tuuli: ${keskituuli} m/s, ${tuulensuunta}°.`;
  }

  if (tienpinta) {
    tienpintatxt = `Tienpinta: ${tienpinta}.`;
  }

  if (nakyvyys) {
    nakytxt = `Näkyvyys: ${nakyvyys} km.`;
  }

  if (sade) {
    sadetxt = `${sade}.`;
  }

  if (nakyvyys && sade) {
    sade = sade.toLowerCase();
    nakytxt = `Näkyvyys: ${nakyvyys} km, ${sade}.`;
    sadetxt = "";
  }

  if (ilmankosteus) {
    ilmankosteustxt = `Ilmankosteus: ${ilmankosteus}%.`;
  }

  let toTime = new Date();
  let timeDiff = toTime.getTime() - lt.getTime();
  let timeDiffMins = Math.floor(timeDiff / 1000 / 60);

  [pvm, aika] = getDateTime(lt);

  const pvm2 = tdiff => {
    if (tdiff > 60) {
      return (
        <span id="red">
          {pvm} klo {aika}
        </span>
      );
    } else {
      return (
        <span id="green">
          {pvm} klo {aika}
        </span>
      );
    }
  };

  pvmtxt = pvm2(timeDiffMins);
  return (
    <span>
      {pvmtxt}: {ilmatxt}
      {tielampotxt} {tuulitxt} {ilmankosteustxt} {tienpintatxt} {nakytxt}{" "}
      {sadetxt}
    </span>
  );
};

export default GetWeather;
