import React, { useState, useEffect } from "react";

const ShowImage = ({ roadStation }) => {
  const url =
    "https://tie.digitraffic.fi/api/v1/data/camera-data/" + roadStation;
  const [stnData, setStnData] = useState([]);
  let stns = [];
  useEffect(() => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error("Tietojen haussa on ongelma: " + response.status);
        }
        return response.json();
      })
      .then(data => {
        for (let i = 0; i < data.cameraStations[0].cameraPresets.length; i++) {
          stns.push(data.cameraStations[0].cameraPresets[i].imageUrl);
        }
        setStnData(stns);
      })
      .catch(err => {
        console.log("Hakuvirhe: ", err);
      });
  }, [url]);

  const links = stnData.map((img, index) => {
    return (
      <a
        className="imglink"
        href={img}
        key={index}
        target="_blank"
        rel="noopener noreferrer"
      >
        {roadStation}
      </a>
    );
  });

  return links;
};

export default ShowImage;
