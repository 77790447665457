import React, { useState, useEffect } from "react";
import ShowImage from "./ShowImage";
import GetWeather from "./GetWeather";
import GetSunCalc from "./GetSunCalc";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
// https://github.com/tannerlinsley/react-table/tree/v6
// npm i -S react-table-v6

const RoadCameras = () => {
  const url = "https://tie.digitraffic.fi/api/v3/metadata/camera-stations";
  const [roadCamData, setRoadCamData] = useState();

  useEffect(() => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error("Tietojen haussa on ongelma: " + response.status);
        }
        return response.json();
      })
      .then(data => setRoadCamData(data.features))
      .catch(err => {
        console.log("Hakuvirhe: ", err);
      });
  }, []);

  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };

  const columns = [
    {
      Header: "Kamera",
      accessor: "id",
      headerStyle: {
        textAlign: "left",
        fontWeight: "bold",
        background: "#d3d3d3"
      },
      style: {
        textAlign: "left"
      },

      Cell: ({ row, original }) => (
        <ShowImage roadStation={original.properties.id} />
      )
    },
    {
      Header: "Nimi",
      accessor: "properties.names.fi",
      foldable: true,
      headerStyle: {
        textAlign: "left",
        fontWeight: "bold",
        background: "#d3d3d3"
      },
      style: {
        textAlign: "left"
      },
      Cell: ({ row, original }) => {
        const lat = original.geometry.coordinates[1];
        const lon = original.geometry.coordinates[0];
        const href = "https://www.google.com/maps/search/";
        const link = href + lat + "," + lon;
        return (
          <a id="geo" href={link} target="_blank" rel="noopener noreferrer">
            {original.properties.names.fi}
          </a>
        );
      }
    },
    {
      Header: "Maakunta",
      accessor: "properties.province",
      foldable: true,
      headerStyle: {
        textAlign: "left",
        fontWeight: "bold",
        background: "#d3d3d3"
      },
      style: {
        textAlign: "left"
      }
    },
    {
      Header: "Kunta",
      accessor: "properties.municipality",
      foldable: true,
      headerStyle: {
        textAlign: "left",
        fontWeight: "bold",
        background: "#d3d3d3"
      },
      style: {
        textAlign: "left"
      }
    },
    {
      expander: true,
      Header: () => <strong>Sää</strong>,
      width: 65,
      Expander: ({ isExpanded, ...rest }) => (
        <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
      ),
      style: {
        cursor: "pointer",
        fontSize: 25,
        padding: "0",
        textAlign: "center",
        userSelect: "none"
      }
    }
  ];

  return (
    <ReactTable
      data={roadCamData}
      columns={columns}
      defaultPageSize={15}
      defaultFilterMethod={filterCaseInsensitive}
      pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
      filterable={true}
      defaultSorted={[
        {
          id: "properties.municipality",
          desc: false
        }
      ]}
      className="-striped -highlight"
      previousText="Edellinen"
      nextText="Seuraava"
      pageText="Sivu"
      ofText="/"
      rowsText="riviä/sivu"
      loadingText="Ladataan tietoja..."
      noDataText="Tietoja ei saatavilla."
      SubComponent={row => (
        <div>
          <GetWeather
            wxStation={row.original.properties.nearestWeatherStationId}
          />
          <GetSunCalc
            lat={row.original.geometry.coordinates[1]}
            lon={row.original.geometry.coordinates[0]}
          />
        </div>
      )}
    />
  );
};

export default RoadCameras;
