import React from "react";
import RoadCameras from "./RoadCameras";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>Suomen tiekelikamerat ja tiesää</h1>
      <RoadCameras />
      <p id="copy">
        {" "}
        © 2020 <span>Tiekeli.fi</span> | Jari Perkiömäki | Liikennetietojen
        lähde:{" "}
        <a
          href="https://www.digitraffic.fi/tieliikenne/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Traffic Management Finland / digitraffic.fi
        </a>
        , lisenssi{" "}
        <a
          href="http://creativecommons.org/licenses/by/4.0/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CC 4.0 BY
        </a>{" "}
        | Rajapintojen{" "}
        <a
          href="https://www.digitraffic.fi/rajapintojen-tila/"
          target="_blank"
          rel="noopener noreferrer"
        >
          tila
        </a>
        . Tämä sivusto ei vastaa tietojen oikeellisuudesta.
      </p>
    </div>
  );
}

export default App;
