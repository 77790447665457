import React from "react";
var SunCalc = require("suncalc");

const GetSunCalc = ({ lat, lon }) => {
  let aurinko = null,
    sunrise = null,
    sunset = null;

  let d = new Date();
  let t = SunCalc.getTimes(d, lat, lon);

  if (isNaN(t.sunrise.getHours())) {
    sunrise = null;
  } else {
    sunrise =
      ("0" + t.sunrise.getHours()).slice(-2) +
      ":" +
      ("0" + t.sunrise.getMinutes()).slice(-2);
  }

  if (isNaN(t.sunset.getHours())) {
    sunset = null;
  } else {
    sunset =
      ("0" + t.sunset.getHours()).slice(-2) +
      ":" +
      ("0" + t.sunset.getMinutes()).slice(-2);
  }

  if (sunrise && !sunset) {
    aurinko = "nousu klo " + sunrise + ".";
  }
  if (!sunrise && sunset) {
    aurinko = "lasku klo " + sunset + ".";
  }
  if (sunrise && sunset) {
    aurinko = sunrise + "-" + sunset + ".";
  }
  if (!sunrise && !sunset) {
    aurinko = "--:--";
  }

  return <span>Aurinko: {aurinko}</span>;
};

export default GetSunCalc;
